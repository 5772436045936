import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Layout from '../components/Layout';
import SEO from '../components/SEO';


const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/iJvUJCxTKuYqbu59PZUg';
// const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/sepia=tone:80/torn_edges=spread:[1,10]/vyl1yGJUSgiYbarZHqJd';
// const backgroundImage = 'https://source.unsplash.com/tzQkuviIuHU';
//const backgroundImage = 'https://source.unsplash.com/NMk1Vggt2hg';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  contactUsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Layout>

      <SEO title="About" />

      <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${backgroundImage})` }}>
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={backgroundImage} alt={'Spooky Cabin In The Woods'} />}
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography variant="h3" color="inherit" paragraph>
                Mission
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                Our mission is to make it as easy as possible to discover and explore the spooky haunted world we live in.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h3">
        About Us
      </Typography>
      <Typography>
        We've always been drawn to the mysteries of the supernatural and greatly enjoy learning the macabre history
        of places we visit. Whether its been exploring our own back yard in Washington state, or new locations as we
        travel, exploring the haunted past and present of where we go is one of our favorite pastimes.
        <br /><br />
        One thing we've noticed however is that it's not always easy to discover what we're looking for.
        The bits and pieces of information are strewn across many different sources that are sometimes difficult
        to unearth and stitch together. 
        <br /><br />
        That's the motivation behind Spookt! We want to help make it as easy as possible for anyone
        to enjoy exploring this haunted spooky world of ours.
      </Typography>

      <Paper className={classes.contactUsContainer}>
        <Typography variant="h4">
          Contact Us
        </Typography>
        <Typography>
          <ul>
            <li>Is there an amazing place or story we've missed?</li>
            <li>Are you running tours and would like to be listed?</li>
            <li>Do you have a great partnership opportunity idea?</li>
          </ul>
          </Typography>
          <Typography>
            We'd love to hear from you! Reach out on our social media channels or shoot us an email at <Link href="mailto:spookt@spookt.com">spookt@spookt.com</Link>
        </Typography>
      </Paper>
    </Layout>
  );
};